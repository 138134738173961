import { ReactComponent as CloseIcon } from 'assets/icons/fa-xmark.svg';
import ExternalLink from 'components/ExternalLink';
import './sharingLinks.scss';

const SharingLinks = ({ toggleSharing, handleToggleSharing }) => {
  return (
    <div className={`sharing-links-container${toggleSharing ? ' open' : ''}`}>
      <div className="close-sharing-btn" onClick={handleToggleSharing}>
        <CloseIcon className="icon" />
      </div>
      <ExternalLink />
    </div>
  );
};

export default SharingLinks;
