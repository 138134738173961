import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import {
  AutoPlayIcon,
  AutoRotateIcon,
  FullScreenIcon,
  HelpIcon,
  FloorMapIcon,
} from 'components/icons';

export default function MobileExperience() {
  const { t } = useTranslation();
  return (
    <div className="experience-slider">
      <Carousel
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        autoPlay
        infiniteLoop
        showArrows
        swipeable
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="left-button"
            ></button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="right-button"
            ></button>
          )
        }
      >
        <div className="click-drag">
          <div
            className="click-drag__icon"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/touch_icon.png)`,
            }}
          ></div>
          <div className="explication">
            {t('drag_to_look_around_the_scene')}
          </div>
        </div>
        <div className="viewer-scene">
          <div
            className="foot-step__icon"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/navigation/circleFixed.png)`,
            }}
          ></div>
          <div className="explication">{t('click_to_view_the_next_scene')}</div>
        </div>
        <div className="viewer-info">
          <div className="viewer-info__icon">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/info-icon.png`}
              alt="info-icon"
            />
          </div>
          <div className="explication">
            {t('click_to_view_the_information')}
          </div>
        </div>
        <div className="info-button">
          <div className="list-button">
            <div className="row-button">
              <div className="icon">
                <AutoPlayIcon />
              </div>
              <div className="explication">{t('autoplay_tour')}</div>
            </div>
            <div className="row-button">
              <div className="icon">
                <HelpIcon />
              </div>
              <div className="explication">
                {t('click_to_view_instructions')}
              </div>
            </div>
            <div className="row-button">
              <div className="icon">
                <AutoRotateIcon
                  shouldRotate={true}
                  activeColor="#fff"
                  defaultColor="#fff"
                />
              </div>
              <div className="explication">
                {t('click_to_toggle_auto_rotation_on_off')}
              </div>
            </div>
            <div className="row-button">
              <div className="icon">
                <FloorMapIcon defaultColor="#fff" />
              </div>
              <div className="explication">
                {t('click_to_toggle_the_2D_map')}
              </div>
            </div>
            <div className="row-button">
              <div className="icon">
                <FullScreenIcon defaultColor="#fff" />
              </div>
              <div className="explication">
                {t('click_to_view_full_screen')}
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}
