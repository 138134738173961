import React, { useState } from 'react';

import './HoveringPlate.scss';

export default function HoveringPlate({ children, textHover }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    setPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };
  return (
    <div
      className="hover-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {children}
      {showTooltip && (
        <div
          className="tooltip"
          style={{ top: `${position.y}px`, left: `${position.x}px` }}
        >
          {textHover && textHover}
        </div>
      )}
    </div>
  );
}
