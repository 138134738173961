import { Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'assets/icons/fa-xmark.svg';
import { ReactComponent as MenuBarIcon } from 'assets/icons/fa-bar.svg';
import './resortMenu.scss';
import ExternalLink from 'components/ExternalLink';
import ResortMenuItem from './ResortMenuItem';

const ResortMenu = ({ currentPano, selectSceneById }) => {
  const [toggleMenuMobile, setToggleMenuMobile] = useState(false);
  const { json } = useSelector((state) => ({
    json: state.json,
  }));

  const menuList = useMemo(() => {
    return json.menu.reduce((list, curMenu) => {
      if (!curMenu.scenes?.length) return list;

      if (curMenu.scenes.length === 1) {
        list.push({
          name: curMenu.scenes[0].title,
          groupId: curMenu.scenes[0].groupId,
          id: curMenu.scenes[0].id,
        });
      } else {
        list.push(curMenu);
      }
      return list;
    }, []);
  }, [json.menu]);

  const handleToggleMenuMobile = useCallback(() => {
    setToggleMenuMobile((value) => !value);
  }, []);

  return (
    <div className="resort-menu-container">
      <div className="scene-name-container">
        <h1 className="scene-name-title">You are in:</h1>
        <h1 className="scene-name">{currentPano?.title}</h1>
      </div>
      <div
        className="menu-btn-toggle mobile-only"
        onClick={handleToggleMenuMobile}
      >
        <MenuBarIcon className="icon" />
      </div>
      <div
        className={`menu-list-bg mobile-only${
          toggleMenuMobile ? ' open-on-mobile' : ''
        }`}
        onClick={handleToggleMenuMobile}
      ></div>
      <div className={`menu-list${toggleMenuMobile ? ' open-on-mobile' : ''}`}>
        <div className="menu-list-item mobile-title">
          <h1 className="menu-list-item-title">Menu</h1>
          <CloseIcon className="icon" onClick={handleToggleMenuMobile} />
        </div>
        {menuList.map((menu, index) => (
          <Fragment key={index}>
            <ResortMenuItem
              menu={menu}
              selectSceneById={selectSceneById}
              handleToggleMenuMobile={handleToggleMenuMobile}
            />
          </Fragment>
        ))}
        <div className="menu-list-item social-links">
          <h1 className="menu-list-item-title">Social medias</h1>
          <div className="submenu-list">
            <ExternalLink />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResortMenu;
