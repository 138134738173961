import DomErrorBoundary from 'components/errors/domError';
import { connect } from 'react-redux';
import FirstRoute from './1stRoute';

const RouteHandlers = (props) => (
  <DomErrorBoundary>
    <FirstRoute {...props} />
    {/* <SecondRoute {...props} />
    <ThirdRoute {...props} />
    <FourthRoute {...props} /> */}
  </DomErrorBoundary>
);

const mapStateToProps = ({ json: { scenes, groups } }) => ({
  scenes,
  groups,
});

export default connect(mapStateToProps)(RouteHandlers);
