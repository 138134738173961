import { useFrame, useThree } from '@react-three/fiber';
import configs from 'configs';
import {
  DEFAULT_ROTATION_X_ANGLE,
  SCALE,
  STEP_WIDTH,
  TOP_Y,
  TRANSFORM_RATIO
} from 'containers/world/constant';
import {
  calcFootRotationY,
  calcScaleForProjection
} from 'containers/world/utils';
import { useEffect, useRef } from 'react';
import { FrontSide } from 'three';
import { cleanLoadTextures } from 'utils/textureHelper';

const JumpSpot = ({ location }) => {
  const meshRef = useRef();
  const { camera } = useThree();
  const scaleRef = useRef({
    factor: 0,
    x: 1,
    y: 1,
  });

  useEffect(() => {
    cleanLoadTextures([
      configs.baseUrl + '/assets/images/navigation/whiteArrow1.png',
    ]).then(([texture]) => {
      if (meshRef.current?.material) {
        meshRef.current.material.map = texture;
        meshRef.current.material.needsUpdate = true;
      }
    });
  }, [meshRef]);

  useEffect(() => {
    const [x, y, z] = location;
    const { scaleX, scaleY } = calcScaleForProjection(y);
    const angleRotate = calcFootRotationY(location);
    meshRef.current.visible = y <= TOP_Y;
    meshRef.current.position.set(
      TRANSFORM_RATIO * x,
      TRANSFORM_RATIO * y,
      TRANSFORM_RATIO * z
    );
    meshRef.current.scale.set(scaleX, scaleY);
    scaleRef.current.factor = 1;
    scaleRef.current.x = scaleX;
    scaleRef.current.y = scaleY;
    meshRef.current.rotation.set(DEFAULT_ROTATION_X_ANGLE, 0, angleRotate);
  }, [location, camera.position]);

  useFrame(() => {
    if (meshRef.current && scaleRef.current.factor !== 0) {
      if (scaleRef.current.factor > 0) {
        const newScale = {
          x: meshRef.current.scale.x + 0.0025,
          y: meshRef.current.scale.y + 0.0025,
          z: meshRef.current.scale.z + 0.0025,
        };
        if (meshRef.current.scale.x > scaleRef.current.x * SCALE) {
          scaleRef.current.factor = -1;
        } else {
          meshRef.current.scale.set(newScale.x, newScale.y, newScale.z);
        }
      } else {
        const newScale = {
          x: meshRef.current.scale.x - 0.0025,
          y: meshRef.current.scale.y - 0.0025,
          z: meshRef.current.scale.z - 0.0025,
        };
        if (meshRef.current.scale.x < scaleRef.current.x) {
          scaleRef.current.factor = 1;
        } else {
          meshRef.current.scale.set(newScale.x, newScale.y, newScale.z);
        }
      }
    }
  });

  return (
    <mesh ref={meshRef}>
      <planeGeometry args={[STEP_WIDTH, STEP_WIDTH]} />
      <meshPhongMaterial
        color={'white'}
        alphaTest={0.5}
        side={FrontSide}
        transparent
        opacity={1.5}
      />
    </mesh>
  );
};

export default JumpSpot;
