import HoveringPlate from 'components/HoveringPlate';
import MenuBarIcon from './MenuBarIcon';
import {
  AquaGreenParkIcon,
  AquaHouse,
  AquaMapfloorplanIconV2,
  AquaVilla,
} from 'components/icons/AquaIcons';
import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ExternalLink from 'components/ExternalLink';
import vizionLogo from 'assets/images/logo/vizion_logo.png';
import './tourMenuDesktop.scss';
import './tourMenuMobile.scss';

const Icons = ({ iconOrder, isActive }) => {
  const iconComponents = [
    <AquaMapfloorplanIconV2 isActive={isActive} />,
    <AquaGreenParkIcon isActive={isActive} />,
    <AquaHouse isActive={isActive} />,
    <AquaVilla isActive={isActive} />,
  ];

  return <>{iconComponents[iconOrder]}</>;
};

const TourMenu = (props) => {
  const {
    isMobile,
    selectSceneById,
    openMenu,
    handleOpenMenu,
    json,
    tour,
    subscriptionPlan,
    currentScene,
  } = props;

  const enableBranding = subscriptionPlan.enableBranding;
  const tourLogo = tour.logoURL || vizionLogo;
  const menuList = json.menu;
  const scenes = json.scenes;

  const [expandedMenuMobile, setExpandedMenuMobile] = useState();

  const scenesIndexById = useMemo(() => {
    return scenes.reduce((scenesIndex, currentScene) => {
      return {
        ...scenesIndex,
        [currentScene._id]: currentScene,
      };
    }, {});
    // eslint-disable-next-line
  }, []);

  const onChangeScene = (groupId, sceneId) => {
    if (isMobile) handleOpenMenu(false);
    selectSceneById(groupId, sceneId);
  };

  const onExpandMenu = (order) => {
    setExpandedMenuMobile((prevState) => {
      if (prevState === order) return null;
      return order;
    });
  };

  return (
    <div className={`tour-menu ${openMenu ? 'menu-open' : 'menu-close'}`}>
      {enableBranding && (
        <div className="tour-logo">
          <HoveringPlate textHover={'Trang chủ'}>
            <img alt="tour-logo" src={tourLogo}></img>
          </HoveringPlate>
        </div>
      )}

      <div className={`menu-items w-100`}>
        <ul className="list-menu-container">
          {menuList.map((item) => (
            <li
              key={item.order}
              className={`menu-items-aqua ${
                expandedMenuMobile === item.order ? 'active' : ''
              }`}
            >
              <div
                className="menu-items-aqua__container"
                onClick={() =>
                  !isMobile
                    ? onChangeScene(item.scenes[0].groupId, item.scenes[0].id)
                    : onExpandMenu(item.order)
                }
              >
                <p className={`menu-items-aqua__title`}>{item.name}</p>
                <Icons iconOrder={item.order} />
              </div>

              <div className={`menu-items-aqua__scenes`}>
                <ul className="scenes-container">
                  {item.scenes?.map((scene) => (
                    <li
                      key={scene._id}
                      className={`scenes-item ${
                        scene.id === currentScene.id &&
                        scene.groupId === currentScene.groupId
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => onChangeScene(scene.groupId, scene.id)}
                    >
                      <p className="scenes-item-title">{scene.title}</p>
                      <i className="icon fas fa-street-view"></i>
                      <div className="scene-preview">
                        <div className="img-container">
                          <img
                            src={scenesIndexById[scene._id]?.previewImgUrl}
                            alt={scene.title}
                          />
                        </div>
                        <p className="description">{scene.title}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>

        <ExternalLink />
      </div>

      <div
        className={`menu-items-aqua__weather ${!openMenu ? 'translateX' : ''}`}
      >
        <p
          className={`menu-items-aqua__weather-title ${
            !openMenu ? 'changeColor' : ''
          }`}
        >
          TP. Hồ Chí Minh:<i className="fa fa-cloud-moon"></i>
          30°C/87°F
        </p>
      </div>

      <MenuBarIcon openMenu={openMenu} handleOpenMenu={handleOpenMenu} />
    </div>
  );
};

const mapStateToProps = (store) => ({
  json: store.json,
  tour: store.tour,
  subscriptionPlan: store.subscriptionPlan,
  currentScene: store.currentScene,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TourMenu);
