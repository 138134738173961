import React from 'react';

import GalleryItem from './GalleryItem';
import CarouselGallery from './CarouselGallery';

import './style.scss';

function PopupGallery(props) {
  const {
    media: { gallery },
  } = props;
  return (
    <div className="Popup-content Popup-content-gallery">
      <CarouselGallery>
        {gallery.map((item, index) => (
          <GalleryItem key={index} item={item} {...props} />
        ))}
      </CarouselGallery>
    </div>
  );
}

export default PopupGallery;
