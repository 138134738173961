import { useState, useEffect, useRef } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import ScenesDragged from './scenesDragged';

const TransformContainer = (props) => {
  const {
    tour,
    media,
    scenes,
    zoom,
    setZoom,
    imgRoot,
    newMedia,
    container,
    tranformRef,
    hotspotHistory,
    selectSceneById,
  } = props;

  const imgRef = useRef();
  const [scale, setScale] = useState(1);
  const [imgReady, setImgReady] = useState(false);
  const [minScale, setMinScale] = useState(1);
  const [maxScale, setMaxScale] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [sizeContainer, setSizeContainer] = useState(null);

  useEffect(() => {
    if (tranformRef?.current) {
      tranformRef.current.setTransform(
        tranformRef.current.state.positionX,
        tranformRef.current.state.positionY,
        zoom
      );
    }
  }, [zoom, tranformRef]);

  useEffect(() => {
    const containerW = container.current.childNodes[0].clientWidth;
    const imgW = imgRoot.width;
    if (container.current && container.current.parentNode.clientWidth > 1023) {
      if (imgW < containerW) {
        setMinScale(0.3);
      } else {
        setMinScale(1);
      }
    }
  }, [container, imgRoot]);

  useEffect(() => {
    imgRef.current.style.opacity = 0;
    setIsLoading(true);
  }, [imgRoot]);

  useEffect(() => {
    if (container.current && imgRoot) {
      const containerW = container.current.childNodes[0].clientWidth;
      const containerH = container.current.childNodes[0].clientHeight;
      const imgW = imgRoot.width;
      const imgH = imgRoot.height;
      const ratioImg = imgW / imgH;
      const ratioCon = containerW / containerH;

      if (ratioImg > ratioCon) {
        setMaxScale(Math.max((2 * imgW) / containerW, (containerH * 2) / imgH));
        return;
      }
      if (imgW > containerW) {
        setMaxScale((imgW * 2) / containerW);
        return;
      }

      setMaxScale(2);
    }
  }, [container, imgRoot]);

  useEffect(() => {
    if (container.current) {
      const scaleImageRatio = container.current.clientWidth / imgRoot.width;
      const height = imgRoot.height * scaleImageRatio;
      const width = container.current.clientWidth;

      setSizeContainer({ width, height });
    }
  }, [container, imgRoot]);

  return (
    <TransformWrapper
      ref={tranformRef}
      scale={scale}
      maxScale={maxScale}
      minScale={minScale}
      wheel={{ step: 0.05 }}
      centerOnInit={true}
      doubleClick={{ disabled: true }}
      scalePadding={{ disabled: true }}
      onWheel={(ref) => {
        setScale(ref.scale);
      }}
      onZoomStop={(ref) => setZoom(ref.state.scale)}
      options={{
        limitToWrapper: false,
      }}
      pan={{
        velocity: true,
        paddingSize: 0,
        velocityEqualToMove: true,
        velocityBaseTime: 400,
        velocityAnimationType: 'easeOutCubic',
      }}
    >
      {() => {
        return (
          <>
            <TransformComponent contentClass="transform-2d-map">
              <>
                <img
                  src={imgRoot.url}
                  alt={media.title}
                  ref={imgRef}
                  onLoad={() => {
                    setTimeout(() => {
                      imgRef.current.style.opacity = 1;
                      setImgReady(true);
                    }, 1000);
                    setIsLoading(false);
                  }}
                />
                {imgReady
                  ? newMedia.mapFloorPlan.objects.map((item) => (
                      <ScenesDragged
                        key={item.id}
                        item={item}
                        imgRoot={imgRoot}
                        scenes={scenes}
                        tour={tour}
                        hotspotHistory={hotspotHistory}
                        selectSceneById={selectSceneById}
                      />
                    ))
                  : null}
                {isLoading && (
                  <div
                    style={{
                      width: sizeContainer.width,
                      height: sizeContainer.height,
                    }}
                  ></div>
                )}
              </>
            </TransformComponent>
            {isLoading ? <p className="loading-placeholder">Loading</p> : null}
          </>
        );
      }}
    </TransformWrapper>
  );
};

export default TransformContainer;
