import HOTSPOT_TYPE from 'consts/hotspotType.const';
import { useMemo } from 'react';
import './avoriazHotspot.scss';
import SceneHotspot from './SceneHotspot';

const AvoriazHotspot = ({ hotspot, groups, onClick }) => {
  const hotspotGroupId = hotspot.scene?.groupId;
  const groupContainsHotspot = useMemo(() => {
    return groups.find((group) => group.id === hotspotGroupId);
  }, [groups, hotspotGroupId]);

  return (
    <div className="avoriaz-hotspot-container">
      <div className="hotspot-circle" onClick={onClick}>
        {hotspot.type === HOTSPOT_TYPE.SCENE ? (
          <SceneHotspot
            groupName={groupContainsHotspot?.title}
            sceneName={hotspot.scene?.title}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AvoriazHotspot;
