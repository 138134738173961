import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import {
  AutoPlayIcon,
  AutoRotateIcon,
  FullScreenIcon,
  HelpIcon,
  FloorMapIcon,
} from 'components/icons';

export default function DesktopExperience() {
  const { t } = useTranslation();

  return (
    <div className="experience-content">
      <div className="click-drag">
        <div
          className="click-drag__icon"
          style={{
            backgroundImage: `url(${
              isMobile
                ? process.env.PUBLIC_URL + '/assets/icons/touch_icon.png'
                : process.env.PUBLIC_URL + '/assets/icons/click-drag.png'
            })`,
          }}
        ></div>
        <div className="explication">
          {isMobile
            ? t('drag_to_look_around_the_scene')
            : t('click_and_drag_to_look_around_the_scene')}
        </div>
      </div>
      <div className="viewer-scene">
        <div
          className="foot-step__icon"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/navigation/circleFixed.png)`,
          }}
        ></div>
        <div className="explication">{t('click_to_view_the_next_scene')}</div>
      </div>
      <div className="viewer-info">
        <div className="viewer-info__icon">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/info-icon.png`}
            alt="info-icon"
          />
        </div>
        <div className="explication">{t('click_to_view_the_information')}</div>
      </div>
      <div className="info-button">
        <div className="list-button">
          <div className="row-button">
            <div className="icon">
              <AutoPlayIcon />
            </div>
            <div className="explication">{t('autoplay_tour')}</div>
          </div>
          <div className="row-button">
            <div className="icon">
              <HelpIcon />
            </div>
            <div className="explication">{t('click_to_view_instructions')}</div>
          </div>
          <div className="row-button">
            <div className="icon">
              <AutoRotateIcon
                shouldRotate={true}
                activeColor="#fff"
                defaultColor="#fff"
              />
            </div>
            <div className="explication">
              {t('click_to_toggle_auto_rotation_on_off')}
            </div>
          </div>
          <div className="row-button">
            <div className="icon">
              <FloorMapIcon defaultColor="#fff" />
            </div>
            <div className="explication">{t('click_to_toggle_the_2D_map')}</div>
          </div>
          <div className="row-button">
            <div className="icon">
              <FullScreenIcon defaultColor="#fff" />
            </div>
            <div className="explication">{t('click_to_view_full_screen')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
