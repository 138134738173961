import AvoriazBackground from "./AvoriazBackground";
import AvoriazLogo from "./AvoriazLogo";
import AvoriazMenu from "./AvoriazMenu";
import AvoriazSceneInformation from "./AvoriazSceneInformation";
import AvoriazSocialLinks from "./AvoriazSocialLinks";

const AvoriazLayout = ({ currentPano, selectSceneById }) => {

  return <div className="avoriaz-layout-container">
    <AvoriazMenu selectSceneById={selectSceneById} />
    <AvoriazLogo />
    <AvoriazSocialLinks />
    <AvoriazSceneInformation currentPano={currentPano} selectSceneById={selectSceneById} />
    <AvoriazBackground />
  </div>
}

export default AvoriazLayout;
