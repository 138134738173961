import React, { useCallback, useMemo } from 'react';
import {
  // AutoRotateIcon,
  // AudioIcon,
  // AutoRotateIcon,
  // ContactFormIcon,
  FloorMapIcon,
  // FullScreenIcon,
  // GyroscopeIcon,
  // HelpIcon,
  MapFloorPlanIcon,
  // ShareIcon,
} from 'components/icons';
// import configs from 'configs';
// import { isMobile } from 'react-device-detect';
import { limitLetters } from 'utils/limitLetters';

// import NotePlate from './NotePlate';

import './SchoolLayoutIcons.scss';

export default function SimpleLayoutIcons(props) {
  const {
    currentPano,
    // isAutoPlay,
    // color,
    // toggleHelpButton,
    featuredMedia,
    // isShowAudioIcon,
    // canFullscreen,
    // menuPosition,
    // handleFullScreen,
    // fullScreen,
    isShowMapFloorPlan,
    setIsActiveMap,
    isActiveMap,
    mapShowed,
    setOpenDropDown,
    openDropDown,
    handleSelectMap,
    scenes,
    floorMaps,
    // switchMode,
    handleUpdateRoute,
    onSelect,
    isSchoolLayout,
    // toggleRotate,
    // shouldRotate,
    // color,
  } = props;

  const defaultSceneIds = useMemo(
    () =>
      window.data.json.groups
        .filter((g) => g.defaultSceneId)
        .map((g) => g.defaultSceneId),
    []
  );

  const handleSwitchMap = useCallback(
    (item) => {
      handleSelectMap(item);
      if (item.objects) {
        const defaultObj = item.objects.find(
          (o) => defaultSceneIds.indexOf(o.id) !== -1
        );
        if (defaultObj) {
          const defaultScene =
            scenes.find((s) => s._id === defaultObj.id) || {};
          if (defaultScene?.groupId && defaultScene?.id) {
            onSelect(defaultScene.groupId, defaultScene.id);
          }
        }
      }
    },
    [handleSelectMap, onSelect, scenes, defaultSceneIds]
  );

  return (
    <div className={`icons_right`}>
      {isShowMapFloorPlan && (
        <>
          <div
            className="icon position-relative school-layout__icon-container"
            onClick={() => setIsActiveMap(!isActiveMap)}
          >
            <MapFloorPlanIcon active={isActiveMap} />
            {/* {!isMobile && (
              <NotePlate note={'Map'} transform={{ x: -100, y: 0 }} />
            )} */}
          </div>
          <div
            className={`mapfloorplan_container ${isActiveMap ? 'open' : ''} ${
              isSchoolLayout ? 'mapfloorplan_container-school_layout' : ''
            }`}
          >
            {mapShowed && (
              <>
                <div className="map_header">
                  <div
                    className="map_dropdown"
                    onClick={() => setOpenDropDown(!openDropDown)}
                  >
                    <span>{limitLetters(mapShowed.image.name, 30)}</span>
                    <i className="map_arrow down"></i>
                    {openDropDown && (
                      <div
                        className={`map_dropdown_options ${
                          openDropDown ? 'show' : ''
                        }`}
                      >
                        <ul className="dropdown_items">
                          {floorMaps?.map((item, index) => (
                            <li
                              onClick={() => handleSwitchMap(item)}
                              key={index}
                            >
                              {limitLetters(item.image.name, 30)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="map_image">
                  <img src={mapShowed.image.url} alt={mapShowed.image.name} />
                  {mapShowed.objects.map((viewpoint) => {
                    const currentPoint = viewpoint.id === currentPano._id;
                    const [left, top] = viewpoint.coordinates;
                    const leftInPercent = left * 100;
                    const topInPercent = top * 100;
                    const currentScene =
                      scenes.find((s) => s._id === viewpoint.id) || {};

                    return (
                      <div
                        key={viewpoint.id}
                        className={`viewpoint-container ${
                          currentPoint ? 'current-viewpoint' : 'viewpoint'
                        } `}
                        style={{
                          left: `${leftInPercent}%`,
                          top: `${topInPercent}%`,
                        }}
                        onClick={() =>
                          onSelect(currentScene.groupId, currentScene.id)
                        }
                      >
                        <p className="scene-title">{currentScene.title}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      )}

      {featuredMedia.map && (
        <div
          className="icon"
          onClick={() => handleUpdateRoute(featuredMedia.map.id)}
        >
          <FloorMapIcon />
        </div>
      )}
    </div>
  );
}
