import { useSelector } from "react-redux";
import vizionLogo from 'assets/images/logo/vizion_logo.png';
import "./avoriazLogo.scss";

const AvoriazLogo = () => {
  const tour = useSelector(state => state.tour);
  const tourLogo = tour.logoURL || vizionLogo;

  return <div className="avoriaz-logo-container">
    <img src={tourLogo} alt="Logo" />
  </div>
}

export default AvoriazLogo;
