import React, { useCallback, useMemo, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { isEqual } from 'lodash';

import { menuHighlightDefaultColor } from 'consts';
import configs from 'configs';
import { EVENT_TYPE, trackEventByType } from 'gaTracking';
import { ensureSubscriptionData, switchControlMode } from 'utils';

import { ShareIcon } from 'components/icons';
import { useNavigatedRoutes } from 'common/hooks';

import {
  setMenuPosition,
  toggleAudio,
  toggleMenu,
  togglePanoMode,
  setControlMode,
} from 'store/actions';

import MenuForMobile from 'components/MenuForMobile';
import MenuForDesktop from 'components/MenuForDesktop';
import { LAYOUT_UI } from 'consts/ui.const';
import HOTSPOT_TYPE from 'consts/hotspotType.const';
import MEDIA_TYPE from 'consts/mediaType.const';
import AquaLayout from 'components/AquaLayoutV2/AquaLayout';
import AvoriazLayout from 'components/AvoriazLayout';
import MobiHomeLayout from 'components/MobiHomeLayout';
import ClearSpaceLayout from 'components/ClearSpaceLayout';
import ResortLayout from 'components/ResortLayout';

const DEFAUlt_MAP_COORDINATES = [0, 0, 10];

function moveItemToTop(arr, targetId) {
  arr.sort((a, b) => b.objects.length - a.objects.length);

  const index = arr.findIndex((item) => {
    return item.objects.some((obj) => obj.id === targetId);
  });

  if (index !== -1) {
    const itemToMove = arr.splice(index, 1)[0];
    arr.unshift(itemToMove);
  }

  return arr;
}

const MenuV2 = (props) => {
  const isPhone = useMediaQuery({ query: '(max-width: 480px)' });
  const {
    logoURL,
    hasLogo,
    isSchoolLayout,
    isAquaStyle,
    isAvoriazStyle,
    isMobihomeStyle,
    isClearSpaceStyle,
    isResortStyle,
    isBrothersFurnitureStyle,
    isSimpleStyle,
  } = useSelector((state) => ({
    logoURL: state.logoURL,
    hasLogo: state.hasLogo,
    isSchoolLayout: state.tour.menuStyle === LAYOUT_UI.SCHOOL,
    isAquaStyle: state.tour.menuStyle === LAYOUT_UI.AACORPORATION,
    isAvoriazStyle: state.tour.menuStyle === LAYOUT_UI.AVORIAZ,
    isMobihomeStyle: state.tour.menuStyle === LAYOUT_UI.MOBIHOME,
    isClearSpaceStyle: state.tour.menuStyle === LAYOUT_UI.CLEAR_SPACE,
    isResortStyle: state.tour.menuStyle === LAYOUT_UI.RESORT,
    isBrothersFurnitureStyle:
      state.tour.menuStyle === LAYOUT_UI.BROTHERS_FURNITURE,
    isSimpleStyle: state.tour.menuStyle === LAYOUT_UI.SIMPLE,
  }));

  const [openMenu, setOpenMenu] = useState(
    !(props.tour.menuStyle === LAYOUT_UI.SCHOOL)
  );

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const enableBranding = useSelector(
    ({ subscriptionPlan }) => subscriptionPlan.enableBranding
  );
  const menuHighlightColor = props.json.tour.menuHighlightColor;

  const dispatch = useDispatch();
  const [handleUpdateRoute] = useNavigatedRoutes();

  const menuOpenScene = (gId, sId) => {
    props.onSelect(gId, sId);
    trackEventByType(EVENT_TYPE.SCENE_FROM_MENU, sId);
  };
  const { controlMode } = useSelector((state) => state);

  const handleSwitchControlMode = useCallback(async () => {
    const switchToMode = await switchControlMode(controlMode);
    if (switchToMode !== controlMode) dispatch(setControlMode(switchToMode));
  }, [controlMode, dispatch]);

  const isShowOnMap = useMemo(
    () => !isEqual(props.tourMapCoordinates, DEFAUlt_MAP_COORDINATES),
    [props.tourMapCoordinates]
  );

  const hightLightColor = useMemo(
    () => (enableBranding ? menuHighlightColor : menuHighlightDefaultColor),
    [enableBranding, menuHighlightColor]
  );

  const isShowAudioIcon = useMemo(() => props.audio.available, [props.audio]);

  const floorMaps = useMemo(() => {
    const mapFloorPlansInHotspot =
      props?.currentPano?.hotspots?.reduce((plans, hs) => {
        let mapPlansInCurrentHotspot = [];
        // get mapFloorPlan array in hotspot OR in media object
        if (
          hs?.type === HOTSPOT_TYPE.MEDIA &&
          hs?.media?.type === MEDIA_TYPE.MAPFLOORPLAN &&
          hs?.media?.mapFloorPlan?.length
        ) {
          mapPlansInCurrentHotspot = hs.media.mapFloorPlan;
        } else if (
          hs?.type === HOTSPOT_TYPE.MAP_FLOOR_PLAN &&
          hs?.mapFloorPlan?.length
        ) {
          mapPlansInCurrentHotspot = hs.mapFloorPlan;
        }

        // get unique floorplan
        const filterMapPlansInCurrentHotspot = mapPlansInCurrentHotspot.reduce(
          (plansInHs, currPlan) => {
            const existed = plans.find(
              (plan) =>
                plan.image.name === currPlan.image.name &&
                plan.image.url === currPlan.image.url
            );
            if (!existed) {
              plansInHs.push(currPlan);
            }
            return plansInHs;
          },
          []
        );

        return plans.concat(filterMapPlansInCurrentHotspot);
      }, []) || [];

    return moveItemToTop(mapFloorPlansInHotspot, props.currentPano?._id);
  }, [props.currentPano]);

  const isShowMapFloorPlan = useMemo(
    () => !!floorMaps.length || props?.currentPano?.isShowMapFloorPlan,
    [floorMaps.length, props?.currentPano?.isShowMapFloorPlan]
  );

  return (
    <>
      <div
        className={`tour-infor ${
          props.isNavOpen ? 'tour-infor_navMenuOpen' : ''
        } ${isSchoolLayout ? 'tour-infor_school-layout' : ''} ${
          isSimpleStyle ? 'simple-style' : ''
        }`}
      >
        {/* TODO: Move 2 blocks below to default & school layout */}
        {enableBranding &&
          hasLogo &&
          !isAquaStyle &&
          !isAvoriazStyle &&
          !isMobihomeStyle &&
          !isClearSpaceStyle &&
          !isResortStyle && (
            <div className={`tour-logo `}>
              <img alt="tour-logo" src={logoURL}></img>
            </div>
          )}

        {!isSchoolLayout &&
          !isAquaStyle &&
          !isAvoriazStyle &&
          !isMobihomeStyle &&
          !isClearSpaceStyle &&
          !isResortStyle &&
          !isBrothersFurnitureStyle &&
          !isSimpleStyle && (
            <div className="tour-icons">
              <div
                className="icon-share"
                onClick={() => handleUpdateRoute(configs.socialRoute)}
              >
                <ShareIcon color={hightLightColor} />
              </div>
            </div>
          )}
      </div>

      {/* TODO: deprecate desktop & mobile components, build layout components for school, default separately */}
      {isAquaStyle ? (
        <AquaLayout
          currentPano={props.currentPano}
          selectSceneById={props.onSelect}
          isShowMapFloorPlan={isShowMapFloorPlan}
          floorMaps={floorMaps}
        />
      ) : isAvoriazStyle ? (
        <AvoriazLayout
          currentPano={props.currentPano}
          selectSceneById={props.onSelect}
        />
      ) : isMobihomeStyle ? (
        <MobiHomeLayout
          currentPano={props.currentPano}
          selectSceneById={props.onSelect}
          isShowMapFloorPlan={isShowMapFloorPlan}
          floorMaps={floorMaps}
          showLanding={props.showLanding}
        />
      ) : isClearSpaceStyle ? (
        <ClearSpaceLayout />
      ) : isResortStyle ? (
        <ResortLayout
          currentPano={props.currentPano}
          selectSceneById={props.onSelect}
          floorMaps={floorMaps}
          isShowMapFloorPlan={isShowMapFloorPlan}
          handleSwitchControlMode={handleSwitchControlMode}
        />
      ) : // backward compatible for old layouts:
      isPhone ? (
        <MenuForMobile
          menuOpenScene={menuOpenScene}
          handleSwitchControlMode={handleSwitchControlMode}
          color={hightLightColor}
          isShowOnMap={isShowOnMap}
          isShowAudioIcon={isShowAudioIcon}
          floorMaps={floorMaps}
          isShowMapFloorPlan={isShowMapFloorPlan}
          isAquaStyle={isAquaStyle}
          imageSize={imageSize}
          setImageSize={setImageSize}
          {...props}
        />
      ) : (
        <MenuForDesktop
          menuOpenScene={menuOpenScene}
          handleSwitchControlMode={handleSwitchControlMode}
          color={hightLightColor}
          isShowOnMap={isShowOnMap}
          isShowAudioIcon={isShowAudioIcon}
          floorMaps={floorMaps}
          isShowMapFloorPlan={isShowMapFloorPlan}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          isAquaStyle={isAquaStyle}
          imageSize={imageSize}
          setImageSize={setImageSize}
          {...props}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  dimentions,
  menu,
  audio,
  json,
  currentScene,
  tour,
}) => ({
  dimentions,
  isNavOpen: menu.isOpen,
  menuPosition: menu.position,
  mapPopupOpen: menu.isoMetricMap,
  socialPopupOpen: menu.social,
  audio,
  json,
  featured: json.featured,
  featuredMedia: json.featuredMedia,
  tourMapCoordinates: json.tour.mapCoordinates,
  showOnMap: currentScene.showOnMap,
  scope: ensureSubscriptionData(tour.subscriptionPlan.scopes),
  tour,
});

const mapDispatchToProps = {
  toggleMenu,
  togglePanoMode,
  setMenuPosition,
  toggleAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuV2);
