import React, { useMemo, useState, useEffect } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';

import ButtonReadMore from '../buttonReadMoreNew';

import { measureElement, getImageRatio } from 'containers/world/utils';

import './GalleryItem.scss';

const EXPAND_WIDTH = 60;

export default function GalleryItem(props) {
  const {
    item: { readMoreUrl, imageUrl, caption, title },
    headerColor,
    bodyColor,
    scrollTop,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpand, setShowExpand] = useState(false);
  const [descriptionWidth, setDescriptionWidth] = useState(0);
  const [truncateWidth, setTruncateWidth] = useState(0);
  const [imgRatio, setImgRatio] = useState(null);
  const [popupHeight, setPopupHeight] = useState(0);
  const [flex, setFlex] = useState('center');

  const adjustedCaption = useMemo(
    () => caption.split('\n').filter((segment) => segment.trim() !== ''),
    [caption]
  );

  const firstSegment = useMemo(() => adjustedCaption[0], [adjustedCaption]);

  const popupContentWpStyle = useMemo(() => {
    if (isMobileOnly) {
      return {
        width:
          imgRatio > 0.75
            ? `calc(100% - 20px)`
            : `calc(${85 * imgRatio}vh - 40px)`,
      };
    }

    if (isTablet) {
      return {
        width: imgRatio > 0.75 ? `calc(100vw - 40px)` : `${85 * imgRatio}vh`,
      };
    }

    return {
      width: imgRatio > 0.75 ? `${67.5 * imgRatio}vh` : `${85 * imgRatio}vh`,
    };
  }, [imgRatio]);

  const toggleExpand = () => {
    setIsExpanded(true);
  };

  const toggleCollapse = () => {
    setIsExpanded(false);
    scrollTop();
  };

  useEffect(() => {
    if (popupHeight && popupHeight > window.innerHeight) {
      setFlex('flex-start');
    } else setFlex('center');
  }, [popupHeight]);

  useEffect(() => {
    if (!descriptionWidth || !truncateWidth) return;
    const threshold = truncateWidth + EXPAND_WIDTH;
    setShowExpand(threshold >= descriptionWidth);
  }, [descriptionWidth, truncateWidth]);

  useEffect(() => {
    getImageRatio(imageUrl)
      .then((result) => {
        setImgRatio(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [imageUrl]);

  return (
    <div className="gallery-item" style={{ alignItems: flex }}>
      <div
        className="Popup-content-wp"
        style={popupContentWpStyle}
        ref={(node) => measureElement(node, 'height', setPopupHeight)}
      >
        <div
          className={`gallery-img-wp ${
            imgRatio > 0.75 ? 'landscape-maxheight' : 'portrait-maxheight'
          }`}
        >
          <img src={imageUrl} alt={title} />
        </div>
        <div className="gallery-text-wp">
          <div className="gallery-title">
            <h2 style={{ color: headerColor, textAlign: 'start' }}>{title}</h2>
            {readMoreUrl && (
              <div className="readmore-btn">
                <ButtonReadMore href={readMoreUrl} color="white" />
              </div>
            )}
          </div>
          <div className="gallery-description">
            {isExpanded ? (
              adjustedCaption.map((segment, idx) => (
                <p key={idx} style={{ color: bodyColor }}>
                  {segment}
                  {idx === adjustedCaption.length - 1 && (
                    <>
                      {' '}
                      <span id="collapse-text" onClick={toggleCollapse}>
                        Hide
                      </span>
                    </>
                  )}
                </p>
              ))
            ) : (
              <p
                ref={(node) =>
                  measureElement(node, 'width', setDescriptionWidth)
                }
                style={{ color: bodyColor }}
              >
                <span
                  id="truncate-text"
                  ref={(node) =>
                    measureElement(node, 'width', setTruncateWidth)
                  }
                >
                  {firstSegment}
                </span>
                {showExpand && (
                  <span id="expand-text" onClick={toggleExpand}>
                    Show
                  </span>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
