import { ReactComponent as CloseIcon } from 'assets/icons/fa-xmark.svg';
import React, { useMemo } from 'react';
import './mapFloorPlan.scss';
import ViewPointMapPlan from './ViewPointMapPlan';

const MapFloorPlan = ({
  currentPano,
  floorMaps,
  selectSceneById,
  toggleMapFloorPlan,
  handleToggleFloorPlan,
}) => {
  const mapShowed = useMemo(() => {
    if (floorMaps.length > 0 && currentPano) {
      const getCurrentMap = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      return getCurrentMap(floorMaps, currentPano);
    } else return;
  }, [floorMaps, currentPano]);

  return (
    <div
      className={`map-floorplan-container${toggleMapFloorPlan ? ' open' : ''}`}
    >
      <div className="map-floorplan-close-btn" onClick={handleToggleFloorPlan}>
        <CloseIcon className='icon' />
      </div>
      <img src={mapShowed.image.url} alt={mapShowed.image.name} />
      {mapShowed.objects?.map((viewpoint) => {
        return (
          <React.Fragment key={viewpoint.id}>
            <ViewPointMapPlan
              viewpoint={viewpoint}
              currentPano={currentPano}
              selectSceneById={selectSceneById}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MapFloorPlan;
