import React from 'react';

function PopupYoutube({ title, videoId }) {
  return (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}?autoplay=0`}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}

export default PopupYoutube;
