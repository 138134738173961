import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect';

import './CarouselGallery.scss';

const CarouselGallery = ({ children }) => {

  return (
    <Carousel
      showStatus={false}
      showIndicators={children.length > 1}
      showThumbs={false}
      // autoPlay
      // infiniteLoop
      // interval={2000}
      showArrows={!isMobile}
      swipeable
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="arrow prev"
          ></button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="arrow next"
          ></button>
        )
      }
    >
      {children}
    </Carousel>
  );
};

export default CarouselGallery;
