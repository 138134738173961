import HOTSPOT_TYPE from 'consts/hotspotType.const';
import MEDIA_TYPE from 'consts/mediaType.const';
import JumpSpotHover from './JumpSpotHover';
import './clearSpaceHotspot.scss';
import InfoTagStyle from './InfoTagStyle';
import ImageStyle from './ImageStyle';

const ClearSpaceHotspot = ({ hotspot, onClick }) => {
  if (hotspot.type === HOTSPOT_TYPE.SCENE)
    return <JumpSpotHover hotspot={hotspot} onClick={onClick} />;

  if (hotspot.type === HOTSPOT_TYPE.MEDIA)
    return (
      <div className="clear-space-media-hotspot-container">
        {hotspot.media?.type === MEDIA_TYPE.INFO_TAG && (
          <InfoTagStyle text={hotspot.media?.title} onClick={onClick} />
        )}
        {hotspot.media?.type === MEDIA_TYPE.IMAGE && (
          <ImageStyle imageURL={hotspot.media?.imageURL} onClick={onClick} />
        )}
      </div>
    );

  return null;
};

export default ClearSpaceHotspot;
