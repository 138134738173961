import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useBlur() {
  const {
    isLandingOpen,
    shouldBlur,
    worldParams: { hotspotId },
  } = useSelector((state) => state);

  const [blur, setBlur] = useState(() => isLandingOpen);

  const updateBlur = (newBlur) => {
    if (blur !== newBlur) {
      setBlur(newBlur);
    }
  };

  useEffect(() => {
    if (isLandingOpen || (hotspotId && shouldBlur)) {
      updateBlur(true);
      return;
    }
    updateBlur(false);
    // eslint-disable-next-line
  }, [hotspotId, isLandingOpen, shouldBlur]);

  return blur;
}
