import { ReactComponent as FacebookIcon } from 'assets/icons/icons8-facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/icons8-instagram.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/icons8-twitter.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/icons8-youtube.svg';
import { SOCIAL_ICON_TYPE } from 'consts/ui.const';
import { ReactComponent as EmailIcon } from 'assets/icons/fa-envelope.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/fa-globe.svg';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import './externalLink.scss';
import { AQUA_MAX_SOCIAL_MEDIA_DISPLAY } from 'consts/aquaLayout';

const ExternalLink = () => {
  const { tour } = useSelector((state) => ({ tour: state.tour }));

  const listSocialTypes = useMemo(() => {
    const types = [];
    if(tour?.email) types.push(SOCIAL_ICON_TYPE.EMAIL);
    if(tour?.websiteUrl) types.push(SOCIAL_ICON_TYPE.WEBSITE);
    if(tour?.youtubeUrl) types.push(SOCIAL_ICON_TYPE.YOUTUBE);
    if(tour?.facebookUrl) types.push(SOCIAL_ICON_TYPE.FACEBOOK);
    if(tour?.twitterUrl) types.push(SOCIAL_ICON_TYPE.TWITTER);
    if(tour?.instagramUrl) types.push(SOCIAL_ICON_TYPE.INSTAGRAM);

    return types.slice(0, AQUA_MAX_SOCIAL_MEDIA_DISPLAY);
    // eslint-disable-next-line
  }, [])

  const renderIcon = (type) => {
    let hrefLink = "";
    let icon = null;
    switch (type) {
      case SOCIAL_ICON_TYPE.EMAIL:
        hrefLink = `mailto:${tour.email}`;
        icon = <EmailIcon />;
        break;
      case SOCIAL_ICON_TYPE.WEBSITE:
        hrefLink = tour?.websiteUrl;
        icon = <WebsiteIcon />;
        break;
      case SOCIAL_ICON_TYPE.YOUTUBE:
        hrefLink = tour?.youtubeUrl;
        icon = <YoutubeIcon />;
        break;
      case SOCIAL_ICON_TYPE.FACEBOOK:
        hrefLink = tour?.facebookUrl;
        icon = <FacebookIcon />;
        break;
      case SOCIAL_ICON_TYPE.TWITTER:
        hrefLink = tour?.twitterUrl;
        icon = <TwitterIcon />;
        break;
      case SOCIAL_ICON_TYPE.INSTAGRAM:
        hrefLink = tour?.instagramUrl;
        icon = <InstagramIcon />;
        break;
      default:
        break;
    }

    return <a
      className="link-item"
      href={hrefLink}
      rel="noreferrer"
      target="_blank"
    >
      {icon}
    </a>
  }

  return (
    <div className="external-links-container">
      {
        listSocialTypes.map((type, index) => (
          <React.Fragment key={index}>
            {renderIcon(type)}
          </React.Fragment>
        ))
      }
    </div>
  );
};

export default ExternalLink;
