import { ReactComponent as CompressIcon } from 'assets/icons/fa-compress.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/fa-expand.svg';
import { ReactComponent as MapLocationDotIcon } from 'assets/icons/fa-map-location-dot.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/fa-question.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/fa-share-nodes.svg';
import { ReactComponent as VolumeOnIcon } from 'assets/icons/fa-volume-high.svg';
import { ReactComponent as VolumeMuteIcon } from 'assets/icons/fa-volume-xmark.svg';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAudio } from 'store/actions';
import { canFullscreen, toggleFullScreen } from 'utils';
import MapFloorPlan from './MapFloorPlan';
import './mobiController.scss';
import SharingLinks from './SharingLinks';

const MobiController = ({
  currentPano,
  isShowMapFloorPlan,
  floorMaps,
  selectSceneById,
  showLanding,
}) => {
  const [toggleMapFloorPlan, setToggleMapFloorPlan] = useState(false);
  const [toggleSharing, setToggleSharing] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const audio = useSelector((state) => state.audio);

  const dispatch = useDispatch();

  const handleToggleAudio = () => {
    dispatch(toggleAudio(!audio.enabled));
  };

  const handleToggleFloorPlan = useCallback(() => {
    setToggleMapFloorPlan((value) => !value);
  }, []);

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const handleToggleHelp = () => {
    showLanding();
  };

  const handleToggleSharing = () => {
    setToggleSharing(value => !value);
  };

  return (
    <div className="mobi-controller-container">
      <div className="controller-list">
        {isShowMapFloorPlan && (
          <div className="controller-list-item">
            {!toggleMapFloorPlan && (
              <div className="controller-list-item-popover">
                <div className="popover-content">Sơ đồ khu vực</div>
              </div>
            )}
            <MapLocationDotIcon
              className="icon"
              onClick={handleToggleFloorPlan}
            />
            <MapFloorPlan
              toggleMapFloorPlan={toggleMapFloorPlan}
              handleToggleFloorPlan={handleToggleFloorPlan}
              currentPano={currentPano}
              floorMaps={floorMaps}
              selectSceneById={selectSceneById}
            />
          </div>
        )}

        {audio?.available && (
          <div className={`controller-list-item`} onClick={handleToggleAudio}>
            <div className="controller-list-item-popover">
              <div className="popover-content">Bật tắt âm thanh</div>
            </div>
            {audio?.enabled ? (
              <VolumeOnIcon className="icon" />
            ) : (
              <VolumeMuteIcon className="icon" />
            )}
          </div>
        )}

        {canFullscreen() && (
          <div className={`controller-list-item`} onClick={handleFullScreen}>
            <div className="controller-list-item-popover">
              <div className="popover-content">Bật tắt xem toàn màn hình</div>
            </div>
            {!fullScreen ? (
              <ExpandIcon className="icon" />
            ) : (
              <CompressIcon className="icon" />
            )}
          </div>
        )}

        <div className={`controller-list-item`}>
          {!toggleSharing && (
            <div className="controller-list-item-popover">
              <div className="popover-content">Chia sẻ</div>
            </div>
          )}
          <ShareIcon className="icon" onClick={handleToggleSharing} />
          <SharingLinks
            toggleSharing={toggleSharing}
            handleToggleSharing={handleToggleSharing}
          />
        </div>

        <div className={`controller-list-item`} onClick={handleToggleHelp}>
          <div className="controller-list-item-popover">
            <div className="popover-content">Hướng dẫn</div>
          </div>
          <HelpIcon className="icon" />
        </div>
      </div>
    </div>
  );
};

export default MobiController;
