export const CANVAS_H = 200;
export const INFOTAG_CANVAS_H = 275;
// because [tan(20deg) = CANVAS_W / CANVAS_H] so:
export const CANVAS_W = CANVAS_H * Math.tan(Math.PI / 9);
export const OFFSET_X = CANVAS_W * 0.5;

export const CANVAS_STYLE = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
};

export const getCanvasX = (x, w) => {
  return CANVAS_W * 0.5;
  // if (x <= w * 0.2) {
  //   return CANVAS_W * 0.5 - OFFSET_X;
  // } else if (x >= w * 0.8) {
  //   return CANVAS_W * 0.5 + OFFSET_X;
  // } else if (x === w * 0.5) {
  //   return CANVAS_W * 0.5;
  // } else {
  //   const diff = Math.abs(x - w * 0.5);
  //   const totalDiff = w * 0.3;
  //   const percent = diff / totalDiff;
  //   const offsetX = OFFSET_X * percent;
  //   if (x < w * 0.5) {
  //     // left
  //     return CANVAS_W * 0.5 - offsetX;
  //   } else {
  //     // right
  //     return CANVAS_W * 0.5 + offsetX;
  //   }
  // }
};
