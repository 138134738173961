import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAudio } from 'store/actions';
import ResortMenu from './ResortMenu';
import ResortController from './ResortController';
import ResortBackground from './ResortBackground';
import ResortAudio from './ResortAudio';
import ResortFloorPlan from './ResortFloorPlan';

const ResortLayout = ({
  currentPano,
  selectSceneById,
  floorMaps,
  isShowMapFloorPlan,
  handleSwitchControlMode,
}) => {
  const didMounted = useRef(false);
  const audio = useSelector((state) => state.audio);
  const dispatch = useDispatch();

  useEffect(() => {
    if (audio?.available && !didMounted.current) {
      didMounted.current = true;
      dispatch(toggleAudio(false));
    }
  }, [audio, dispatch]);

  return (
    <div className="resort-layout-container">
      <ResortMenu currentPano={currentPano} selectSceneById={selectSceneById} />
      <ResortBackground />
      <ResortController handleSwitchControlMode={handleSwitchControlMode} />
      <ResortAudio />
      {isShowMapFloorPlan && (
        <ResortFloorPlan
          currentPano={currentPano}
          floorMaps={floorMaps}
          selectSceneById={selectSceneById}
        />
      )}
    </div>
  );
};

export default ResortLayout;
