import configs from 'configs';

const SceneHotspot = ({ groupName, sceneName }) => {
  return (
    <>
      <div className="hotspot-circle-info">
        {groupName && (
          <h3 className="hotspot-circle-info--group-name">
            <span className="dot-line"></span>
            {groupName}
          </h3>
        )}
        <h2 className="hotspot-circle-info--scene-name">{sceneName}</h2>
      </div>
      <div className="hotspot-circle-bg">
        <img
          src={configs.baseUrl + '/assets/images/navigation/circleFixed.png'}
          alt=""
        />
      </div>
    </>
  );
};

export default SceneHotspot;
