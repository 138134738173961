import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18next';

import { GlobalIcon, ArrowUp } from 'components/icons';

import './style.scss';

const LANGUAGES = {
  en: 'english',
  vi: 'vietnamese',
  jp: 'japanese',
};

const DEFAULT_LANGUAGE = 'English';

const DropupLanguage = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  function getInitialLanguage() {
    const currentLanguage = i18n.language;
    return t(LANGUAGES[currentLanguage]) || t(DEFAULT_LANGUAGE);
  }
  const [selectedLang, setSelectedLang] = useState(getInitialLanguage());
  const contentRef = useRef(null);

  const handleSelectLanguage = (languageKey) => {
    setSelectedLang(t(LANGUAGES[languageKey]));
    i18n.changeLanguage(languageKey);
    setIsActive(false);
  };

  return (
    <div className="dropup-container">
      <div
        ref={contentRef}
        className="dropup-content"
        style={{ display: isActive ? 'block' : 'none' }}
      >
        {Object.keys(LANGUAGES).map((languageKey) => (
          <div
            key={languageKey}
            onClick={() => handleSelectLanguage(languageKey)}
            className="item"
          >
            {t(LANGUAGES[languageKey])}
          </div>
        ))}
        <div className="item">Empty Item</div>
      </div>
      <div
        onClick={(e) => {
          setIsActive(!isActive);
        }}
        className="dropup-btn"
      >
        <div className="global-icon">
          <GlobalIcon />
        </div>
        <div className="language-selected">{selectedLang}</div>
        <div className={`arrow-icon ${isActive ? 'rotate' : ''}`}>
          <ArrowUp />
        </div>
      </div>
    </div>
  );
};

export default DropupLanguage;
