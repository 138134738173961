import { ReactComponent as CompressIcon } from 'assets/icons/fa-compress.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/fa-expand.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/fa-pause.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/fa-play.svg';
import { ReactComponent as VRCardboardIcon } from 'assets/icons/fa-vr-cardboard.svg';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { setAutoRotate } from 'store/actions';
import { canFullscreen, toggleFullScreen } from 'utils';
import './resortController.scss';

const ResortController = ({ handleSwitchControlMode }) => {
  const { autoRotate, tour } = useSelector((state) => ({
    tour: state.tour,
    autoRotate: state.autoRotate,
  }));
  const [fullScreen, setFullScreen] = useState(false);

  const dispatch = useDispatch();

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const toggleAutoRotate = () => {
    dispatch(setAutoRotate(!autoRotate));
  };

  return (
    <div className="resort-controller-container">
      <div className="controller-list">
        {isMobile && tour.enableGyroscope && (
          <div
            className="controller-list-item"
            onClick={handleSwitchControlMode}
          >
            <VRCardboardIcon className="icon" />
          </div>
        )}

        {canFullscreen() && (
          <div className={`controller-list-item`} onClick={handleFullScreen}>
            <div className="controller-tooltip">Bật tắt xem toàn màn hình</div>
            {!fullScreen ? (
              <ExpandIcon className="icon" />
            ) : (
              <CompressIcon className="icon" />
            )}
          </div>
        )}

        {!!tour.autoRotateSpeed && (
          <div className="controller-list-item" onClick={toggleAutoRotate}>
            <div className="controller-tooltip">Tự động xoay camera</div>
            {!autoRotate ? (
              <PlayIcon className="icon" />
            ) : (
              <PauseIcon className="icon" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResortController;
