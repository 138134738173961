import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSearchParams } from 'store/actions';

const RouteHandler = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tourId, groupId, sceneId, hotspotId } = useSelector(
    (state) => state.worldParams
  );

  console.log(tourId, groupId, sceneId, hotspotId);

  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const queries = useMemo(() => {
    const data = {};
    query.forEach((value, key) => {
      data[key] = value;
    });
    return data;
  }, [query]);

  useEffect(() => {
    dispatch(setSearchParams(queries));
  }, [queries, dispatch]);

  useEffect(() => {
    const routeParams = [tourId, groupId, sceneId, hotspotId].filter(Boolean);
    history.push(`/${routeParams.join('/')}`);
  }, [groupId, history, hotspotId, sceneId, tourId]);

  return <></>;
};

export default withRouter(RouteHandler);
