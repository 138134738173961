import { ReactComponent as FaImageIcon } from 'assets/icons/fa-image.svg';
import { ReactComponent as FaImagesIcon } from 'assets/icons/fa-images.svg';
import { ReactComponent as FaMapIcon } from 'assets/icons/fa-map.svg';
import {
  AquaLargeMapfloorplanIcon,
  AquaSceneMenuIcon,
} from 'components/icons/AquaIcons';
import { connect } from 'react-redux';
import './navMenu.scss';
import { AQUA_MODAL_TYPE } from 'consts/aquaLayout';
import { useMemo } from 'react';
import MEDIA_TYPE from 'consts/mediaType.const';

const NavMenu = (props) => {
  const { json, selectSceneById, currentScene, handleOpenAquaModal } = props;

  const scenes = json.scenes;
  const groups = json.groups;
  const mediasMenu = json.media;

  const getParseSceneId = (sceneId) => {
    const foundScene = scenes.find((sce) => sce._id === sceneId);
    return foundScene.id;
  };

  const menuFloorPlansFromMedia = useMemo(() => {
    return mediasMenu.reduce((mapList, media) => {
      if (media.type === MEDIA_TYPE.MAPFLOORPLAN && media.mapFloorPlan?.length) {
        mapList.push(media);
      }
      return mapList;
    }, [])
  }, [mediasMenu])

  const onClickMapPlan = (mediaHotspot) => {
    handleOpenAquaModal({
      type: AQUA_MODAL_TYPE.FLOOR_PLAN,
      mediaHotspot: mediaHotspot,
    });
  };

  return (
    <div className={`aqua-menu-right__menu${!menuFloorPlansFromMedia.length ? " hide-mapfloor-icon" : ""}`}>
      <div className="menu-item">
        <AquaSceneMenuIcon />
        <div className="scene-group-container">
          {groups?.length
            ? groups.map((group) => {
                if (!group.scenes?.length) return null;
                return (
                  <div className={`scene-group`} key={group.id}>
                    <p className="scene-group-title">
                      <FaImagesIcon />
                      {group.title}
                    </p>
                    <div className="scene-list-items">
                      {group.scenes.map((scene, index) => (
                        <div
                          className={`scene-item ${
                            scene.id === currentScene._id &&
                            group.id === currentScene.groupId
                              ? 'active'
                              : ''
                          }`}
                          key={scene.id}
                          onClick={() =>
                            selectSceneById(group.id, getParseSceneId(scene.id))
                          }
                        >
                          <p className="scene-item-title">
                            <FaImageIcon />
                            {scene.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {menuFloorPlansFromMedia.length ? (
        <div className="menu-item">
          <AquaLargeMapfloorplanIcon />
          <div className={`media-group-container`}>
            <div className="media-list-items">
              {menuFloorPlansFromMedia.map((mediaItem, index) => (
                <div
                  className="media-item"
                  key={index}
                  onClick={() => onClickMapPlan(mediaItem)}
                >
                  <p className="media-item-title">
                    <FaMapIcon />
                    {mediaItem.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (store) => ({
  json: store.json,
  currentScene: store.currentScene,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
