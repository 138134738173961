import { useNavigatedRoutes } from 'common/hooks';
import HoveringPlate from 'components/HoveringPlate';
import {
  AudioIcon,
  ContactFormIcon,
  FloorMapIcon,
  HelpIcon,
} from 'components/icons';
import {
  AquaAutoRotateIcon,
  AquaFullscreenIcon,
  AquaLocationIcon,
  AquaMapfloorplanIconV1,
} from 'components/icons/AquaIcons';
import { menuHighlightDefaultColor } from 'consts';
import { AQUA_MODAL_TYPE } from 'consts/aquaLayout';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { setAutoRotate } from 'store/actions';
import { canFullscreen, toggleFullScreen } from 'utils';
import MapPlanPreview from './MapPlanPreview';
import './controlMenu.scss';

const ControlMenu = (props) => {
  const {
    isShowMapFloorPlan,
    floorMaps,
    currentPano,
    selectSceneById,
    handleOpenAquaModal,

    // store
    menuPosition,
    tour,
    setAutoRotate,
    autoRotate,
    featuredMedia,
    subscriptionPlan,
    audio,
    json,
  } = props;

  const [handleUpdateRoute] = useNavigatedRoutes();

  const [isActiveMap, setIsActiveMap] = useState(false);
  const [mapShowed, setMapShowed] = useState();
  const [fullScreen, setFullScreen] = useState(false);

  const isShowAudioIcon = audio.available;
  const enableBranding = subscriptionPlan.enableBranding;
  const menuHighlightColor = json.tour.menuHighlightColor;

  const hightLightColor = useMemo(
    () => (enableBranding ? menuHighlightColor : menuHighlightDefaultColor),
    [enableBranding, menuHighlightColor]
  );

  const mapCoordinates = json.tour.mapCoordinates;
  const mapEmbedUrl = useMemo(() => {
    if (!mapCoordinates?.length) return null;
    const lat = mapCoordinates[0];
    const lon = mapCoordinates[1];
    const z = Math.floor(mapCoordinates[2]);

    return `//maps.google.com/maps?q=${lon},${lat}&z=${z}&output=embed`;
  }, [mapCoordinates]);

  const switchMode = () => {
    setAutoRotate(!autoRotate);
  };

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const toggleHelpButton = () => {
    handleOpenAquaModal({ type: AQUA_MODAL_TYPE.HELP });
  };

  useEffect(() => {
    if (floorMaps.length > 0 && currentPano) {
      const getCurrentMap = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      const intialMap = getCurrentMap(floorMaps, currentPano);
      setMapShowed(intialMap);
    }
  }, [floorMaps, currentPano]);

  return (
    <div className="control-menu-container">
      <div className="control-buttons">
        {isShowMapFloorPlan && (
          <>
            <HoveringPlate textHover={'Mặt bằng tổng thể'}>
              <div
                className={`icon position-relative icon-aquaStyle`}
                onClick={() => setIsActiveMap(!isActiveMap)}
              >
                <AquaMapfloorplanIconV1 />
              </div>
            </HoveringPlate>
          </>
        )}

        {/* Youtube link not supported yet */}
        {/* <HoveringPlate textHover={'Video'}>
          <div
            className={`icon icon-aquaStyle`}
            onClick={() => {
              setIsOpen(true);
              setIframeObject({ url: YOUTUBEIFRAME, type: 'youtube' });
            }}
          >
            <AquaVideoIcon />
          </div>
        </HoveringPlate> */}

        {mapEmbedUrl && (
          <HoveringPlate textHover={'Vị trí'}>
            <div
              className={`icon icon-aquaStyle`}
              onClick={() => {
                handleOpenAquaModal({
                  url: mapEmbedUrl,
                  type: AQUA_MODAL_TYPE.MAP,
                });
              }}
            >
              <AquaLocationIcon />
            </div>
          </HoveringPlate>
        )}

        {!isMobile && !!tour.autoRotateSpeed && (
          <HoveringPlate textHover={'Bật tham quan tự động'}>
            <div className={`icon icon-aquaStyle`} onClick={() => switchMode()}>
              <AquaAutoRotateIcon />
            </div>
          </HoveringPlate>
        )}

        {canFullscreen() && menuPosition !== 'bottom' && (
          <HoveringPlate textHover={'Xem toàn màn hình'}>
            <div className={`icon icon-aquaStyle`} onClick={handleFullScreen}>
              <AquaFullscreenIcon />
            </div>
          </HoveringPlate>
        )}

        <HoveringPlate textHover={'Hướng dẫn'}>
          <div className={`icon icon-aquaStyle`} onClick={toggleHelpButton}>
            <HelpIcon />
          </div>
        </HoveringPlate>

        {featuredMedia.map && (
          <div
            className={`icon icon-aquaStyle`}
            onClick={() => handleUpdateRoute(featuredMedia.map.id)}
          >
            <FloorMapIcon />
          </div>
        )}

        {featuredMedia.contact && (
          <div
            className={`icon icon-aquaStyle`}
            onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
          >
            <ContactFormIcon />
          </div>
        )}
        {isShowAudioIcon && (
          <AudioIcon
            activeColor={hightLightColor}
            className={`icon icon-aquaStyle`}
          />
        )}

        {/* <HoveringPlate textHover={'Chia sẻ'}>
          <div className={`icon icon-aquaStyle`} onClick={handleShare}>
            <ShareIcon />
          </div>
        </HoveringPlate> */}
      </div>

      {isShowMapFloorPlan && (
        <MapPlanPreview
          floorMaps={floorMaps}
          mapShowed={mapShowed}
          isActiveMap={isActiveMap}
          setMapShowed={setMapShowed}
          currentPano={currentPano}
          selectSceneById={selectSceneById}
          setIsActiveMap={setIsActiveMap}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  menu,
  json,
  autoRotate,
  audio,
  subscriptionPlan,
  tour,
}) => ({
  menuPosition: menu.position,
  json,
  autoRotate,
  featuredMedia: json.featuredMedia,
  audio,
  subscriptionPlan: subscriptionPlan.enableBranding,
  tour,
});

const mapDispatchToProps = {
  setAutoRotate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlMenu);
