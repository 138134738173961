import React, { useEffect, useMemo, useState } from 'react';
import ViewPointMapPlan from './ViewPointMapPlan';
import { ReactComponent as CaretDownIcon } from 'assets/icons/fa-caret-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/fa-xmark.svg';
import './resortFloorPlan.scss';

const ResortFloorPlan = ({ currentPano, floorMaps, selectSceneById }) => {
  const [toggleFloorPlan, setToggleFloorPlan] = useState(false);
  const [selectedMap, setSelectedMap] = useState();

  useEffect(() => {
    if (floorMaps.length > 0 && currentPano) {
      const getCurrentMap = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      const currentMap = getCurrentMap(floorMaps, currentPano);
      setSelectedMap(currentMap);
    }
  }, [floorMaps, currentPano]);

  const filteredFloorPlans = useMemo(() => {
    return floorMaps.filter((map) => map.image.url !== selectedMap?.image.url);
  }, [selectedMap, floorMaps]);

  const handleToggleFloorPlan = () => {
    setToggleFloorPlan((value) => !value);
  };

  const handleChangeFloorPlan = (floorPlanItem) => {
    setSelectedMap(floorPlanItem);
  };

  return (
    <div className="resort-floorplan-container">
      <div className="floorplan-btn-container">
        {toggleFloorPlan &&
          filteredFloorPlans.map((map, index) => (
            <div
              className="floorplan-btn floorplan-btn-item"
              key={index}
              title={map.image?.name}
              onClick={() => handleChangeFloorPlan(map)}
            >
              {map.image?.name}
            </div>
          ))}

        <div
          className="floorplan-btn floorplan-btn-toggle-menu"
          onClick={handleToggleFloorPlan}
        >
          {toggleFloorPlan ? (
            <CloseIcon className="icon icon-close" />
          ) : (
            <CaretDownIcon className="icon icon-floorplan" />
          )}
          {toggleFloorPlan ? 'Đóng bản vẽ' : 'Xem bản vẽ'}
        </div>
      </div>

      {selectedMap && (
        <div
          className={`floorplan-img-container${toggleFloorPlan ? ' open' : ''}`}
        >
          <div className="img-container">
            <img src={selectedMap.image.url} alt={selectedMap.image.name} />
          </div>
          {selectedMap.objects?.map((viewpoint) => {
            return (
              <React.Fragment key={viewpoint.id}>
                <ViewPointMapPlan
                  viewpoint={viewpoint}
                  currentPano={currentPano}
                  selectSceneById={selectSceneById}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ResortFloorPlan;
