import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './BackToDefaultSceneButton.scss';

export const BackToDefaultSceneButton = ({ currentPano, menuList }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState();
  const isFirst = useRef(true);

  const isActive = useMemo(() => {
    const scenesInGroupOne = menuList[menuList.length - 1].scenes;
    const lastScene =
      menuList[menuList.length - 1].scenes[scenesInGroupOne.length - 1];

    return currentPano?.id === lastScene.id;
  }, [currentPano, menuList]);

  useEffect(() => {
    if (isActive && isFirst.current) {
      setShow(true);
      isFirst.current = false;
    }
  }, [isActive, show]);

  useEffect(() => {
    if (show)
      setTimeout(() => {
        setShow(false);
      }, 5000);
  }, [show]);

  useEffect(() => {
    isFirst.current = true;
  }, [currentPano]);

  return (
    <>
      <svg
        width="151px"
        height="151px"
        viewBox="0 -32 576 576"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className={`overview-binoculars ${isActive ? 'warning' : ''}`}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <g id="SVGRepo_iconCarrier">
          <path
            stroke="currentColor"
            strokeWidth="1.392"
            d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
          />
        </g>
      </svg>

      {isActive && <div className="overview-point"></div>}
      <div className={`overview-warning ${show ? 'show' : ''}`}>
        {t('last_scene_warning')}
      </div>
    </>
  );
};
