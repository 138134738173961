import React, { useMemo } from 'react';

import { connect, useSelector } from 'react-redux';
import { isArray } from 'lodash';

import { ensureSubscriptionData } from 'utils';
import MenuV2 from 'containers/menuV2';

import PopupHandle from './popupHandle';
import AmbientAudio from '../audio';
import HandleAutoRotate from './handleAutoRotate';

import './style.scss';
import { TitleFollowCursor } from 'components/TitleFollowCursor';
import MouseGuideline from 'components/MouseGuideline';

const World2D = (props) => {
  const {
    json,
    tour,
    currentPano,
    autoRotate,
    showSpinner,
    showIntro,
    hotspotHistory,
    shouldRotate,
    selectSceneById,
  } = props;

  const { viewMode, worldParams } = useSelector((state) => state);
  const location = useMemo(() => {
    const { groupId } = worldParams;
    if (isArray(json.groups) && json.groups.length > 0 && groupId) {
      const currentGroup = json.groups.find((g) => g.id === groupId) || {};
      return currentGroup.location || '';
    }
    return tour.location;
  }, [json.groups, tour.location, worldParams]);

  return (
    <div className={`World-2D-Contents ${viewMode || ''}`}>
      <TitleFollowCursor />
      {json.menu && !showIntro && (
        <MenuV2
          menuList={json.menu}
          scenes={json.scenes}
          groups={json.groups}
          currentPano={currentPano}
          autoRotate={autoRotate}
          shouldRotate={shouldRotate}
          showSpinner={showSpinner}
          toggleRotate={props.toggleRotate}
          toggleFeatured={props.toggleFeatured}
          onSelect={props.selectSceneById}
          toggleMap={props.toggleMap}
          subTitle={location}
          control={props.control}
          switchControl={props.switchControl}
          showLanding={props.onClickPoweredBy}
        />
      )}

      {/* <PoweredBy
        introStatus={introStatus}
        tour={json.tour}
        onSelect={props.selectSceneById}
        scenes={json.scenes}
      /> */}

      <HandleAutoRotate
        autoRotate={autoRotate}
        shouldRotate={shouldRotate}
        scenes={json.scenes}
        currentPano={currentPano}
        onChangeScene={props.selectSceneById}
      />

      <PopupHandle
        mediasList={json.media}
        mediaGroups={json.mediaGroups}
        menuList={json.menu}
        tour={json.tour}
        scenes={json.scenes}
        hotspotHistory={hotspotHistory}
        history={props.history}
        currentPano={currentPano}
        selectSceneById={selectSceneById}
      />
      {currentPano && (
        <>
          <AmbientAudio
            scene={currentPano}
            spinnerOn={showSpinner}
            introOn={showIntro}
          />
          <MouseGuideline />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  json: store.json || {},
  currentHotspot: store.currentHotspot,
  mapPopupOpen: store.menu.isoMetricMap,
  socialPopupOpen: store.menu.social,
  tour: store.json.tour || null,
  scope: ensureSubscriptionData(store.tour.subscriptionPlan.scopes),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(World2D);
