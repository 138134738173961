import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import NotFound from './NotFound';
import configs from './configs';
import { cleanLoadTexture } from './utils/textureHelper';
import Search from './containers/Search';
import World from './containers/world';

window.defaultTexture = null;
cleanLoadTexture(configs.baseUrl + '/assets/images/white-square.png').then(
  (texture) => {
    window.defaultTexture = texture;
  }
);

function AppRoutes() {
  return (
    <BrowserRouter basename={configs.baseUrl}>
      {(configs.singleTour && (
        <Switch>
          <Route
            exact
            path="/not-found"
            render={(props) => <NotFound {...props} />}
          />
          <Route
            path="/:sceneId/:hotspotId"
            render={(props) => <World {...props} />}
          />
          <Route path="/:sceneId" render={(props) => <World {...props} />} />
          <Route path="/" render={(props) => <World {...props} />} />
          <Route path="*" render={(props) => <NotFound {...props} />} />
        </Switch>
      )) || (
        <Switch>
          <Route
            exact
            path="/not-found"
            render={(props) => <NotFound {...props} />}
          />
          <Route
            path="/:tourId/:groupId/:sceneId/:hotspotId"
            render={(props) => <World {...props} />}
          />
          <Route
            path="/:tourId/:groupId/:sceneId/:hotspotId"
            render={(props) => <World {...props} />}
          />
          <Route
            path="/:tourId/:groupId/:sceneId"
            render={(props) => <World {...props} />}
          />
          <Route
            path="/:tourId/:groupId"
            render={(props) => <World {...props} />}
          />
          <Route path="/:tourId" render={(props) => <World {...props} />} />
          <Route path="/" render={(props) => <Search {...props} />} />
          <Route path="*" render={(props) => <NotFound {...props} />} />
        </Switch>
      )}
    </BrowserRouter>
  );
}

export default AppRoutes;
