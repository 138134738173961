const MobiBackground = () => {
  const fixedPosStyle = {
    position: 'fixed',
    left: 0,
    right: 0,
    height: '30%',
    pointerEvents: 'none',
  };

  return (
    <>
      <div
        className="top-soft-bg-linear"
        style={{
          ...fixedPosStyle,
          top: 0,
          background:
            'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.35) 0%, rgba(0,212,255,0) 100%)',
        }}
      ></div>
      <div
        className="bottom-soft-bg-linear"
        style={{
          ...fixedPosStyle,
          bottom: 0,
          background:
            'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.55) 0%, rgba(0,212,255,0) 100%)',
        }}
      ></div>
    </>
  );
};

export default MobiBackground;
