import { connect } from 'react-redux';
import './avoriazSceneInformation.scss';
import { useMemo } from 'react';
import HOTSPOT_TYPE from 'consts/hotspotType.const';
import MEDIA_TYPE from 'consts/mediaType.const';

const AvoriazSceneInformation = ({ currentPano, json, selectSceneById }) => {
  const groupList = json.groups;
  const sceneList = json.scenes;

  const groupContainsScene = useMemo(() => {
    return groupList.find((group) => group.id === currentPano?.groupId);
  }, [groupList, currentPano?.groupId]);

  const firstInfoTagMediaHs = useMemo(() => {
    if (!currentPano?.hotspots?.length) return;
    return currentPano.hotspots.find(
      (hs) =>
        hs.type === HOTSPOT_TYPE.MEDIA && hs.media?.type === MEDIA_TYPE.INFO_TAG
    );
  }, [currentPano?.hotspots]);

  const goToFirstSceneInGroup = () => {
    if(!groupContainsScene?.scenes?.length) return;
    const firstSceneData = sceneList?.find(scene => scene._id === groupContainsScene.scenes[0].id)
    if (!firstSceneData || firstSceneData?._id === currentPano?._id) return;
    selectSceneById(groupContainsScene.id, firstSceneData.id);
  }

  if (!currentPano) return null;

  return (
    <div className="avoriaz-scene-info-container">
      <h1 className="scene-title">{currentPano.title}</h1>
      {groupContainsScene?.title && (
        <p className="group-title" onClick={goToFirstSceneInGroup}>
          <span className="dot-line"></span>
          {groupContainsScene.title}
        </p>
      )}
      {firstInfoTagMediaHs?.media?.infoTag?.text && (
        <p className="info-tag-description">
          {firstInfoTagMediaHs.media.infoTag.text}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  json: state.json,
});

export default connect(mapStateToProps)(AvoriazSceneInformation);
