import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAudio } from "store/actions";
import ClearSpaceLogo from "./ClearSpaceLogo";

const ClearSpaceLayout = () => {
  const audio = useSelector(state => state.audio);
  const dispatch = useDispatch();

  useEffect(() => {
    if(audio?.available) dispatch(toggleAudio(false));
  },[audio, dispatch])

  return <div className="clear-space-container">
    <ClearSpaceLogo />
  </div>
}

export default ClearSpaceLayout;
